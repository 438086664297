import React, {useEffect, useRef, useState} from "react";
import {DataTable} from 'primereact/datatable';
import {Column, ColumnFilterElementTemplateOptions} from 'primereact/column';
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {getCampaignContactsWithPagination, getCampaignDirectoriesPhoneType, getUsers} from "redux/api/api";
import {Button} from "primereact/button";
import {Paginator} from "primereact/paginator";
import {setCampaignContactsPageSize} from "../../../redux/actions/actions";
import {MVP} from "../../../redux/config";
import {classNames} from "primereact/utils";
import {MultiSelect} from "primereact/multiselect";
import FileUploader from "./FileUploader";
import {Dropdown} from "primereact/dropdown";
import {activeDict} from "../../../dictionaries";
import {Calendar} from "primereact/calendar";

const CompaniesListDialogLeads = () => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const campaignContactsPageSize = useAppSelector(state => state.CompaniesListReducer.campaignContactsPageSize)
    // @ts-ignore
    const campaignContacts = useAppSelector(state => state.CompaniesListReducer.campaignContacts)
    // @ts-ignore
    const contactsLoader = useAppSelector(state => state.CompaniesListReducer.contactsLoader)
    const companies = useAppSelector(state => state.CompaniesListReducer)
    // @ts-ignore
    const campaignDirectoriesPhoneType = useAppSelector(state => state.CompaniesListReducer.campaignDirectoriesPhoneType)
    // @ts-ignore
    const clearLeadsFilters = useAppSelector(state => state.CompaniesListReducer.clearLeadsFilters)
    const user = useAppSelector(state => state.UsersReducer.userData);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [sortOrder, setSortOrder] = useState<number>(0);
    const dataTableRef = useRef<DataTable | any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(false);
    const [filterContactName, setFilterContactName] = useState<any>(null);
    const [filterIsDone, setFilterIsDone] = useState<any>(null);
    const [filterPhoneNo, setFilterPhoneNo] = useState<any>(null);
    const [filterPhoneTypeId, setFilterPhoneTypeId] = useState<any>(null);
    const [filterDescription, setFilterDescription] = useState<any>(null);
    const [filterIsActive, setFilterIsActive] = useState<any>(null)
    const [first, setFirst] = useState(0);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [filterDateTime, setFilterDateTime] = useState<any>(false);
    const [filterEndDateTime, setFilterEndDateTime] = useState<any>(false);
    const [startTime, setStartTime] = useState<any>(null);
    const [endTime, setEndTime] = useState<any>(null);
    // @ts-ignore
    const campaignId = companies?.companiesData?.campaign?.campaignId;

    useEffect(() => {
        dispatch(getCampaignDirectoriesPhoneType(jwtToken?.jwtToken))
    }, []);

    useEffect(() => {
        if (campaignContactsPageSize.page !== 1 || campaignContactsPageSize.size !== 10) {
            dispatch(setCampaignContactsPageSize({
                page: 1,
                size: 10
            }));
        }
    }, [campaignId])

    interface DataSort {
        pageNumber: number;
        pageSize: number;
        sort: {
            orderBy: string;
            isDesc: boolean;
        };
        campaignId: number;
        filter?: { key: string; value: string }[];
    }

    const formatDate = (dateString: Date) => {
        const dateObj = new Date(dateString);

        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObj.getDate().toString().padStart(2, "0");
        const year = dateObj.getFullYear();

        return `${month}/${day}/${year}`;
    };

    const getTime = (dateString: Date) => {
        const dateObj = new Date(dateString);
        const hours = dateObj.getHours().toString().padStart(2, "0");
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");
        const seconds = dateObj.getSeconds().toString().padStart(2, "0");

        return `${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        if (campaignId) {
            let filter: { key: string; value: string }[] = [];

            const dataSort: DataSort = {
                pageNumber: campaignContactsPageSize.page,
                pageSize: campaignContactsPageSize.size,
                sort: {
                    orderBy: orderBy ? orderBy : "ContactName",
                    isDesc: isDescFilter
                },
                campaignId: campaignId
            }

            if (filterContactName?.value) {
                filter.push(filterContactName);
            }
            if (filterPhoneTypeId) {
                filterPhoneTypeId.map((item: any) => {
                    filter.push(item);
                })
            }
            if (filterIsDone) {
                filterIsDone.map((item: any) => {
                    filter.push(item);
                })
            }
            if (filterDateTime && filterEndDateTime) {
                let startDate = formatDate(filterDateTime);
                let endDate = formatDate(filterEndDateTime);

                if (startTime && endTime) {
                    let filterStartTime = getTime(startTime);
                    let filterEndTime = getTime(endTime);
                    filter.push({
                        "key": "CreateDateTime",
                        "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
                    })
                }
            }
            if (filterPhoneNo?.value) {
                filter.push(filterPhoneNo);
            }
            if (filterIsActive?.value) {
                filter.push(filterIsActive);
            }
            if (filterDescription?.value) {
                filter.push(filterDescription);
            }

            if (filter) {
                dataSort['filter'] = filter;
            }

            dispatch(getCampaignContactsWithPagination(dataSort, jwtToken?.jwtToken));
        }
    }, [campaignContactsPageSize, orderBy, isDescFilter, filterPhoneTypeId, filterContactName, filterDescription, filterPhoneNo]);

    const headerTemplate = (data: any) => {
        return (
            <div className="max-w-16rem">
                {data.contactName}
            </div>
        );
    }

    const typeNumberTemplate = (data: any) => {
        return (
            <React.Fragment>
                <span>{campaignDirectoriesPhoneType.map((item: any) => item.dictionaryId === data.phoneTypeId ? item.name : '')}</span>
            </React.Fragment>
        )
    }

    const calledTemplate = (data: any) => {
        return (
            <React.Fragment>
                <div className="pl-2">
                    <span>{data?.countCalled + ' ' + `/` + ' ' + data?.countCalls}</span>
                </div>
            </React.Fragment>
        )
    }

    const formatDateTime = (dateString: any) => {
        const date = new Date(dateString);
        return `${date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'})} ${date.toLocaleDateString('en-GB')}`;
    };

    const dateTemplate = (data: any) => {
        return (
            <React.Fragment>
                <div className="pl-2 pt-0">
                    <span>{formatDateTime(data?.createDateTime)}</span>
                </div>
            </React.Fragment>
        )
    }
    const isActiveTemplate = (data: any) => {
        return (
            <React.Fragment>
                <div className="pl-2">
                    <span>{data?.isActive ? 'Активний' : 'Неактивний'}</span>
                </div>
            </React.Fragment>
        )
    }

    const statusTemplate = (data: any) => {
        return (
            <React.Fragment>
                {data?.result !== 'NoCall' && (<span className={`customer-badge ${data?.result === 'Success' ? 'status-6' : 'status-5'}`} style={{borderRadius: `6px`, minWidth: '93px'}}>{data.isDone ? 'success' : 'unsuccess'}</span>)}
            </React.Fragment>
        )
    }

    const onPageChange = (event: any) => {
        setFirst(event.first);
        dispatch(setCampaignContactsPageSize({
            page: event.page + 1,
            size: event.rows
        }))
    };

    const customSort = (event: any) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;
            switch (sortField) {
                case 'contactName':
                    newOrderBy = 'ContactName';
                    break;
                case 'phoneTypeId':
                    newOrderBy = 'PhoneTypeId';
                    break;
                case 'CountCalled':
                    newOrderBy = 'CountCalled';
                    break;
                case 'IsDone':
                    newOrderBy = 'IsDone';
                    break;
                case 'IsActive':
                    newOrderBy = 'IsActive';
                    break;
                case 'CreateDateTime':
                    newOrderBy = 'CreateDateTime';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };

    const filterClearTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-times" onClick={(e) => {
            if (options.filterClearCallback) {
                options.filterClearCallback(e);
            }
            if (options.field === 'createdDateTime') {
                setStartTime(null);
                setEndTime(null);
                setFilterDateTime(null);
                setFilterEndDateTime(null);
            }}} className="p-button-secondary"></Button>;
    }

    const filterApplyTemplate = (options: any) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} disabled={
            (options.field === 'createdDateTime' && (!startTime || !endTime || !filterDateTime || !filterEndDateTime))
            } className=""></Button>
    }

    const header = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center" onClick={() => customSort({'sortField': sortField})}>
                {field}
                <div className={sortIcon}></div>
            </div>
        );
    };

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setSortOrder(0);
    };

    const handleRefresh = () => {
        handleReset();
        dispatch(setCampaignContactsPageSize({
            page: 1,
            size: 10
        }));
    };

    const customFilter = (event?: any) => {
        if (event) {
            const {phoneNo, contactName, phoneTypeId, description, result, isActive, createdDateTime} = event.filters;
            setFilterContactName(contactName ? {key: 'ContactName', value: contactName.constraints[0].value} : null);
            setFilterPhoneNo(phoneNo ? {key: 'PhoneNo', value: phoneNo.constraints[0].value} : null);
            setFilterDescription(description ? {key: 'Description', value: description.constraints[0].value} : null);
            setFilterIsActive(isActive ? {key: 'IsActive', value: isActive.value} : null);
            setFilterDateTime(createdDateTime.value || null);

            if (phoneTypeId.value) {
                setFilterPhoneTypeId(phoneTypeId.value.map((item: any) => ({key: 'PhoneTypeId', value: String(item)})));
            } else {
                setFilterPhoneTypeId(null);
            }
            if (result.value) {
                setFilterIsDone(result.value.map((item: any) => ({key: 'Result', value: String(item)})));
            } else {
                setFilterIsDone(null);
            }
        } else {
            setFilterContactName(null);
            setFilterPhoneNo(null);
            setFilterPhoneTypeId(null);
            setFilterDescription(null);
            setOrderBy(null);
            setIsDescFilter(false);
            setFilterIsDone(null);
            setFilterDateTime(null);
            setFilterIsActive(null);
            setStartTime(null);
            setEndTime(null);
            setFilterEndDateTime(null);
        }
    }

    const typeFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть тип</div>
                <MultiSelect
                    value={options.value}
                    options={campaignDirectoriesPhoneType && campaignDirectoriesPhoneType.map((item: { dictionaryId: number; }) => item.dictionaryId)}
                    itemTemplate={typeItemTemplate}
                    selectedItemTemplate={selectedTypeTemplate}
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                    onMouseDown={() => dispatch(getUsers(user))}
                />
            </>
        );
    };

    const isActiveFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть тип</div>
                <Dropdown
                    options={activeDict}
                    optionLabel="value"
                    optionValue="code"
                    value={options.value}
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const dateFilterTemplate = (options: any) => {
        return <>
            <span className="title">Виберіть дату</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar className="mt-2" value={filterDateTime ? filterDateTime : null} onChange={(e: any) => {
                    options.filterCallback(e.value, options.index);
                    setFilterDateTime(e.value)
                }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar className="mt-2" value={filterEndDateTime ? filterEndDateTime : null} onChange={(e: any) => {
                    setFilterEndDateTime(e.value)
                }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
            </div>
            <div className="flex max-w-15rem">
                <div className="md:col-6">
                    <div className="time-item">
                        <span className="title">З*</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            value={startTime ? startTime : null}
                            timeOnly
                            onChange={(e) => setStartTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                </div>
                <div className="md:col-6">
                    <div className="time-item">
                        <span className="title">По*</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            value={endTime ? endTime : null}
                            required
                            timeOnly
                            onChange={(e) => setEndTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                </div>
            </div>
        </>
    };

    const isDoneSelect = [
        { name: 'Unsuccess', code: 'Unsuccess' },
        { name: 'Success', code: 'Success' },
        { name: 'NoCall', code: 'NoCall' },
    ];


    const isDoneFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть тип</div>
                <MultiSelect
                    value={options.value}
                    options={isDoneSelect}
                    onChange={(e: any) => options.filterCallback(e.value)}
                    placeholder="нічого не обрано"
                    optionLabel="name"
                    optionValue="code"
                    className="p-column-filter"
                />
            </>
        );
    };

    const selectedTypeTemplate = (option: any) => {
        let typeName = campaignDirectoriesPhoneType.map((item: { dictionaryId: number; name: any; }) => {
            if (item.dictionaryId === option) {
                return item.name
            }
        })
        if (option) {
            return (
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            );
        }

        return "Оберіть тип";
    }

    const typeItemTemplate = (option: any) => {
        let typeName = campaignDirectoriesPhoneType.map((item: { dictionaryId: number; name: any; }) => {
            if (item.dictionaryId === option) {
                return item.name
            }
        })
        return (
            <div className="p-multiselect-representative-option">
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {typeName}
                </span>
            </div>
        );
    }

    const rowClass = (data: any) => {
        return {
            'h-4rem max-w-full': true,
            'surface-100': !data.isActive
        };
    };

    return (
        <div className="leads">
            <div className="grid p-fluid">
                <div className="field col-12 md:col-12 mb-0">
                    <div className="flex justify-content-end">
                        <div className=" flex ml-2 mb-0 justify-content-end ">
                            <Button loading={contactsLoader} onClick={handleRefresh} type="button" label="Оновити список" icon="pi pi-refresh" className="p-button-outlined mr-2 max-w-12rem"/>
                        </div>
                        <div className=" flex ml-2 mb-0 justify-content-end ">
                            <Button onClick={handleReset} type="button" label="Очистити фільтри" icon="pi pi-filter-slash" className="p-button-outlined mr-2 max-w-12rem"/>
                            {!MVP && <FileUploader/>}
                        </div>
                    </div>
                </div>
                <div className="field col-12 md:col-12">
                    <DataTable value={campaignContacts?.items}
                               rowGroupMode={orderBy === 'ContactName' || orderBy === null ? 'subheader' : 'undefined'}
                               ref={dataTableRef}
                               rowClassName={rowClass}
                               filters={clearLeadsFilters}
                               sortField="contactName"
                               sortMode="single"
                               onSort={customSort}
                               onFilter={customFilter}
                               groupRowsBy={orderBy === 'ContactName' || orderBy === null ? 'contact' : 'undefined'}
                               //testFix
                               // rowGroupHeaderTemplate={headerTemplate}
                               loading={contactsLoader}
                               className={classNames('campaignContacts', {'active-header-column': orderBy === 'ContactName', 'orderByPhoneType': orderBy === 'PhoneTypeId'})}
                    >
                        <Column
                            style={{cursor: 'pointer'}}
                            sortable
                            filter
                            field={orderBy === 'ContactName' || orderBy === null ? '' : 'ContactName'}
                            filterField="contactName"
                            sortField="contactName"
                            header={header('Назва ліда', 'ContactName')}
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                            filterClear={filterClearTemplate}
                            filterApply={filterApplyTemplate}
                            filterPlaceholder="Введіть назву лїда"
                            //testFix
                            body={headerTemplate}
                            className={classNames('', {'active-header-column': orderBy === 'ContactName'})}/>
                        <Column
                            style={{cursor: 'pointer'}}
                            sortable
                            filter
                            field="isActive"
                            filterField="isActive"
                            sortField="isActive"
                            header={header('Активний', 'IsActive')}
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                            filterClear={filterClearTemplate}
                            filterApply={filterApplyTemplate}
                            filterElement={isActiveFilterTemplate}
                            filterPlaceholder="Введіть назву лїда"
                            body={isActiveTemplate}
                            className={classNames('', {'active-header-column': orderBy === 'IsActive'})}/>
                        <Column
                            style={{cursor: 'pointer', width: '10%'}}
                            filter
                            field="phoneNo"
                            header="Номер"
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                            filterClear={filterClearTemplate}
                            filterApply={filterApplyTemplate}
                            filterPlaceholder="Введіть номер"
                        />
                        <Column
                            headerStyle={{minWidth: '100px'}}
                            style={{cursor: 'pointer', width: '10%'}}

                            header={header('Тип номера', 'PhoneTypeId')}
                            filter
                            filterElement={typeFilterTemplate}
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                            filterClear={filterClearTemplate}
                            filterApply={filterApplyTemplate}
                            className={classNames('', {'active-header-column': orderBy === 'PhoneTypeId'})}
                            sortable
                            field="phoneTypeId"
                            body={typeNumberTemplate}/>
                        <Column
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                            filterClear={filterClearTemplate}
                            filterApply={filterApplyTemplate}
                            filterPlaceholder="Введіть коментар"
                            filter
                            field="description"
                            style={{cursor: 'pointer', width: '10%'}}
                            header="Коментар"/>
                        <Column
                            sortable
                            body={calledTemplate}
                            header={header('Спроби дозвону', 'CountCalled')}
                            style={{cursor: 'pointer', width: '10%'}}
                            className={classNames('', {'active-header-column': orderBy === 'CountCalled'})}
                            />
                        <Column
                            sortable
                            filterClear={filterClearTemplate}
                            filterApply={filterApplyTemplate}
                            filter
                            filterElement={dateFilterTemplate}
                            field="createdDateTime"
                            filterField="createdDateTime"
                            body={dateTemplate}
                            header={header('Дата створення', 'CreateDateTime')}
                            style={{cursor: 'pointer', width: '10%'}}
                            className={classNames('', {'active-header-column': orderBy === 'Date'})}
                            sortField="createdDateTime"
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                            filterPlaceholder="Введіть назву лїда"
                        />
                        <Column
                            style={{cursor: 'pointer', width: '10%'}}
                            header={header('Результат', 'IsDone')}
                            className={classNames('', {'active-header-column': orderBy === 'IsDone'})}
                            sortable
                            filterPlaceholder="Оберіть тип"
                            filter
                            filterClear={filterClearTemplate}
                            filterApply={filterApplyTemplate}
                            field="result"
                            showFilterMatchModes={false}
                            showFilterMenuOptions={false}
                            filterElement={isDoneFilterTemplate}
                            body={statusTemplate}/>
                    </DataTable>
                    <Paginator
                        first={first}
                        rows={campaignContactsPageSize.size}
                        totalRecords={campaignContacts.totalItems}
                        rowsPerPageOptions={[5, 10, 15, 25, 50]}
                        onPageChange={onPageChange}
                        className="border-top-1 border-bottom-1"
                    />
                </div>
            </div>
        </div>
    )
}

export default CompaniesListDialogLeads
