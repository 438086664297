import {Button} from "primereact/button";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {getNotification, getUsers, updateStateCampaign} from "redux/api/api";
import {ConfirmDialog} from "primereact/confirmdialog";
import {getItem} from "../../../redux/cache/cacheService";
import {ProgressBar} from "primereact/progressbar";
import {setDialogVisible} from "../../../redux/actions/actions";
import {codes} from "../../../redux/notificationCodes";
import {checkPermissions} from "../../../redux/permissions/permissionsUtils";

const CompaniesListDialogManagement = () => {
    const dispatch = useAppDispatch()
    // @ts-ignore
    const companiesData = useAppSelector(state => state.CompaniesListReducer.companiesData)
    const companies = useAppSelector(state => state.CompaniesListReducer)
    // @ts-ignore
    const archiveTableToggle = useAppSelector(state => state.CompaniesListReducer.archiveTableToggle)
    const [cancelNotificationStatusChange, setCancelNotificationStatusChange] = useState<boolean>(false);
    const [launchNotificationStatusChange, setLaunchNotificationStatusChange] = useState<boolean>(false);
    const [suspendNotificationStatusChange, setSuspendNotificationStatusChange] = useState<boolean>(false);
    const user = useAppSelector(state => state.UsersReducer.userData);
    let statusId = companiesData?.campaign?.statusId;
    // const statusDone = companiesData?.campaign?.statusId === 6;
    const statusDone = false;
    const rolesVision = useAppSelector(state => state.RolesReducer.rolesVision) as unknown as Record<string, string[]>;
    const dialerPermissions = checkPermissions(rolesVision, 'Dialer');
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);

    const {
        update: hasDialerUpdatePermission,
        delete: hasDialerDeletePermission,
    } = dialerPermissions;
    const StateCampaign = {
        "campaignId": companiesData?.campaign?.campaignId,
        "statusId": null,
        "userId": user?.userId
    }

    useEffect(() => {
        dispatch(getUsers(user))
        statusId = companiesData?.campaign?.statusId;
    }, []);

    const createdUserTime = () => {
        const time = new Date(companiesData?.campaignData?.createdDateTime).toLocaleString("uk-UA")
        return companiesData?.campaignData?.createdDateTime ? time : '-'
    }

    const updatedUserTime = () => {
        const time = new Date(companiesData?.campaignData?.updatedDateTime).toLocaleString("uk-UA")
        return companiesData?.campaignData?.updatedDateTime ? time : '-'
    }

    const updateCampaign = (campaignData: any, statusId: number) => {
        campaignData.statusId = statusId;
        dispatch(updateStateCampaign(campaignData, jwtToken?.jwtToken))
        if (statusId === 5) {
            dispatch(setDialogVisible(false));
        }
    };

    let cancelNotification = getItem(codes.cancel);
    let launchNotification = getItem(codes.launch);
    let suspendNotification = getItem(codes.suspend);

    const progressBar = <ProgressBar mode="indeterminate" style={{ height: '2px', maxWidth: '95%' }}></ProgressBar>;

    const getNotificationMessage = (type: number) => {
        switch (type) {
            case 1:
                if (!cancelNotification && user.userId) {
                    dispatch(getNotification(user.userId, 107, user));
                }
                setCancelNotificationStatusChange(true);
                break;
            case 2:
                if(!launchNotification && user.userId) {
                    dispatch(getNotification(user.userId, 105, user));
                }
                setLaunchNotificationStatusChange(true);
                break;
            case 3:
                if(!suspendNotification && user.userId) {
                    dispatch(getNotification(user.userId, 106, user));
                }
                setSuspendNotificationStatusChange(true);
                break;
            default:
        }
    }

    // @ts-ignore
    let launchMessage = <div>{companies && companies.notificationLaunch ? companies.notificationLaunch.text : launchNotification ? launchNotification : progressBar}</div>
    // @ts-ignore
    let cancelMessage = <div>{companies && companies.notificationCancel ? companies.notificationCancel.text : cancelNotification ? cancelNotification : progressBar }</div>
    // @ts-ignore
    let suspendMessage = <div>{companies && companies.notificationSuspend ? companies.notificationSuspend.text : suspendNotification ? suspendNotification : progressBar}</div>

    return (
        <div className="grid p-fluid management">
            <ConfirmDialog visible={cancelNotificationStatusChange} onHide={() => setCancelNotificationStatusChange(false)} message={cancelMessage}
                           header="Попередження:" icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => updateCampaign(StateCampaign, 5)}/>
            <ConfirmDialog visible={launchNotificationStatusChange} onHide={() => setLaunchNotificationStatusChange(false)} message={launchMessage}
                           header="Попередження:" icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => updateCampaign(StateCampaign, 2)}/>
            <ConfirmDialog visible={suspendNotificationStatusChange} onHide={() => setSuspendNotificationStatusChange(false)} message={suspendMessage}
                           header="Попередження:" icon="pi pi-exclamation-triangle" acceptLabel="Продовжити" rejectLabel="Відмінити" accept={() => updateCampaign(StateCampaign, 4)}/>
            <div className="field col-12 md:col-3">
                {!!companiesData.campaign?.campaignId && <div className="item">
                    ID: {companiesData?.campaign?.campaignId ? companiesData.campaign.campaignId : ''}
                </div>}
            </div>
            <div className="field col-12 md:col-3">
                <div className="item controls-buttons">
                    {hasDialerDeletePermission && (<div className="flex flex-column justify-content-center">
                        <span className="text-field">Відмінити</span>
                        <Button disabled={!archiveTableToggle || !companiesData.campaign.campaignId || statusDone} onClick={() => getNotificationMessage(1)} icon="pi pi-stop" className="p-button-text my-0 mx-auto"/>
                    </div>)}
                    {statusId !== 2 && statusId !== 3  && hasDialerUpdatePermission ? <div className="flex flex-column justify-content-center">
                        <span className="text-field">Запустити</span>
                        <Button disabled={!archiveTableToggle || !companiesData.campaign.campaignId || statusDone} onClick={() => getNotificationMessage(2)} icon="pi pi-play" className="p-button-text my-0 mx-auto"/>
                    </div>: ''}
                    {statusId === 2 || statusId === 3 && hasDialerUpdatePermission ? <div className="flex flex-column justify-content-center">
                        <span className="text-field">Призупинити</span>
                        <Button disabled={!archiveTableToggle || !companiesData.campaign.campaignId || statusDone} onClick={() => getNotificationMessage(3)} icon="pi pi-pause" className="p-button-text my-0 mx-auto"/>
                    </div>: <></>}
                </div>
            </div>
            <div className="field col-12 md:col-6 mb-0">
                {!!companiesData.campaign.campaignId && <div className="field col-12 md:col-12 flex flex-wrap p-0 m-0">
                    {companiesData.campaignData?.createdDateTime && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                        <div className="field col-12 md:col-2 flex p-0 m-0">Створено:</div>
                        <div className="field col-12 md:col-6 flex p-0 m-0">{companiesData.campaignData?.userCreate ? companiesData.campaignData?.userCreate : '-'}</div>
                        <div className="field col-12 md:col-4 flex p-0 m-0">{createdUserTime()}</div>
                    </div>}
                    {companiesData.campaignData?.updatedDateTime && <div className="field col-12 md:col-12 flex flex-wrap m-0">
                        <div className="field col-12 md:col-2 flex p-0 m-0">Оновлено:</div>
                        <div className="field col-12 md:col-6 flex p-0 m-0">{companiesData.campaignData?.userUpdate ? companiesData.campaignData?.userUpdate : '-'}</div>
                        <div className="field col-12 md:col-4 flex p-0 m-0">{updatedUserTime()}</div>
                    </div>}
                </div>}
            </div>
        </div>
    )
}

export default CompaniesListDialogManagement
