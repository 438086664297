import {
    ACCOUNT_WEB_LOGIN, CHANGE_CURRENT_USER_PASSWORD_SUCCESS,
    ERROR_ACCOUNT_WEB_LOGIN, FIRST_TAB_ACCOUNT_LOGIN, INITIAL_ACCOUNT_DATA,
    SET_USER_CONNECTED,
    SUCCESS_ACCOUNT_WEB_LOGIN
} from "../types/types";
import {SET_STATE_DIALOG, SET_USER_FIELD} from "../types/typesUser";
import {SET_EDIT_TYPE} from "../types/typesUsersList";

const initialState = {
    message: null,
    editType: false,
    statusCode: null,
    userConnected: false,
    closeDialogWindow: false,
    firstTabLogin: false,
    userData: {
        jwtToken: {
            jwtToken: null,
            expiresIn: null
        },
        hash: null,
        userName: null,
        userId: null,
        lastName: null,
        firstName: null,
        middleName: null,
        email: null,
        theme: null,
        phoneNo: null,
        pass: null,
        isValidPassword: true
    },
    changeCurrentUserPasswordSuccess: null
}

export const UsersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ACCOUNT_WEB_LOGIN:
            return {...state, userData: action.payload};
        case SUCCESS_ACCOUNT_WEB_LOGIN:
            return {...state, message: null, statusCode: action.payload.statusCode, userConnected: action.payload.userConnected};
        case SET_USER_CONNECTED:
            return {...state, message: null, statusCode: action.payload.statusCode, userConnected: action.payload};
        case SET_USER_FIELD:
            return {
                ...state, companiesData:
                    {
                        ...state.userData,
                        [action.payload.section]: {
                            // @ts-ignore
                            ...state.companiesData[action.payload.section],
                            [action.payload.fieldName]: action.payload.value,
                        }
                    }
            }
        case INITIAL_ACCOUNT_DATA:
            return {...state, userData: initialState.userData}
        case ERROR_ACCOUNT_WEB_LOGIN:
            return {...state, message: action.payload.message, statusCode: action.payload.statusCode};
        case FIRST_TAB_ACCOUNT_LOGIN:
            return {...state, firstTabLogin: action.payload};
        case SET_EDIT_TYPE:
            return {...state, editType: action.payload};
        case SET_STATE_DIALOG:
            return {...state, closeDialogWindow: action.payload};
        case CHANGE_CURRENT_USER_PASSWORD_SUCCESS:
            return {...state, changeCurrentUserPasswordSuccess: action.payload};
        default:
            return state;
    }
};
