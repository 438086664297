export const ARCHIVE_TABLE_TOGGLE = 'COMPANIES_LIST/ARCHIVE_TABLE_TOGGLE'
export const DIALOG_VISIBLE = 'COMPANIES_LIST/DIALOG_VISIBLE'
export const CLEAR_FILTERS = 'COMPANIES_LIST/CLEAR_FILTERS'
export const CLEAR_LEADS_FILTERS = 'CLEAR_LEADS_FILTERS'
export const SELECTED_COMPANY_ID = 'COMPANIES_LIST/SELECTED_COMPANY_ID'
export const COMPANY_FIELD = 'COMPANIES_LIST/COMPANY_FIELD'
export const ADD_USER = 'COMPANIES_LIST/ADD_USER'
export const DELETE_USER = 'COMPANIES_LIST/DELETE_USER'
export const SHOW_LOADER = 'COMPANIES_LIST/SHOW_LOADER'
export const HIDE_LOADER = 'COMPANIES_LIST/HIDE_LOADER'
export const SHOW_ERROR = 'COMPANIES_LIST/SHOW_ERROR'
export const GOOGLE_AUTH_SHOW_ERROR = 'GOOGLE_AUTH_SHOW_ERROR'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE'
export const HIDE_ERROR = 'COMPANIES_LIST/HIDE_ERROR'
export const SHOW_COMPANY_CREATE_MESSAGE = 'COMPANIES_LIST/SHOW_COMPANY_CREATE_MESSAGE'
export const SHOW_COMPANY_SAVE_MESSAGE = 'COMPANIES_LIST/SHOW_COMPANY_SAVE_MESSAGE'
export const HIDE_COMPANY_CREATE_MESSAGE = 'COMPANIES_LIST/HIDE_COMPANY_CREATE_MESSAGE'
export const HIDE_COMPANY_SAVE_MESSAGE = 'COMPANIES_LIST/HIDE_COMPANY_SAVE_MESSAGE'
export const SHOW_LOADER_STRATEGY_DICT = 'COMPANIES_LIST/SHOW_LOADER_STRATEGY_DICT'
export const HIDE_LOADER_STRATEGY_DICT = 'COMPANIES_LIST/HIDE_LOADER_STRATEGY_DICT'
export const INITIAL_COMPANIES_DATA = 'COMPANIES_LIST/INITIAL_COMPANIES_DATA'
export const COMPANY_STATE_SAVE = 'COMPANIES_LIST/COMPANY_STATE_SAVE'

export const GET_COMPANIES = 'COMPANIES_LIST/GET_COMPANIES'
export const GET_COMPANIES_ARCHIVED = 'COMPANIES_LIST/GET_COMPANIES_ARCHIVED'
export const GET_USERS = 'COMPANIES_LIST/GET_USERS'
export const GET_ALL_SIMPLE_USERS = 'GET_ALL_SIMPLE_USERS'
export const GET_COMPANY = 'COMPANIES_LIST/GET_COMPANY'
export const GET_COMPANIES_DIRECTORIES_CAMPAIGN_TYPE = 'COMPANIES_LIST/GET_COMPANIES_DIRECTORIES_CAMPAIGN_TYPE'
export const GET_COMPANIES_DIRECTORIES_SCHEDULER_TYPE = 'GET_COMPANIES_DIRECTORIES_SCHEDULER_TYPE'
export const GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY = 'COMPANIES_LIST/GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY'
export const GET_COMPANIES_DIRECTORIES_PHONE_TYPE = 'COMPANIES_LIST/GET_COMPANIES_DIRECTORIES_PHONE_TYPE'
export const GET_COMPANIES_DIRECTORIES_TRANKS_PHONE = 'COMPANIES_LIST/GET_COMPANIES_DIRECTORIES_TRANKS_PHONES'

export const ACCOUNT_WEB_LOGIN = 'ACCOUNT_WEB/LOGIN'
export const SUCCESS_ACCOUNT_WEB_LOGIN = 'SUCCESS_ACCOUNT_WEB_LOGIN'
export const FIRST_TAB_ACCOUNT_LOGIN = 'FIRST_TAB_ACCOUNT_LOGIN'
export const ERROR_ACCOUNT_WEB_LOGIN = 'ERROR_ACCOUNT_WEB_LOGIN'
export const SHOW_ERROR_FIELD = 'SHOW_ERROR_FIELD'
export const GET_NOTIFICATION = 'NOTIFICATION/LANG_NOTIFICATION/'

export const CHANGED_SCHEDULER = 'CHANGED_SCHEDULER'
export const CHANGED_COMPANIES_DATA = 'CHANGED_COMPANIES_DATA'
export const RESET_SCHEDULER  = 'RESET_SCHEDULER'

export const SET_CALL_CYCLES = 'SET_CALL_CYCLES'
export const SET_TRANK_ID = 'SET_TRANK_ID'
export const SHOW_UPDATED_ERROR = 'SHOW_UPDATED_ERROR'

export const SET_ERROR_FIELDS = 'SET_ERROR_FIELDS';
export const SET_INTERVAL_ID = 'SET_INTERVAL_ID'
export const SHOW_GET_COMPANIES_INTERVAL_ERROR = 'SHOW_GET_COMPANIES_INTERVAL_ERROR'

export const SHOW_GET_ARCHIVE_COMPANIES_ERROR = 'SHOW_GET_ARCHIVE_COMPANIES_ERROR'
export const SHOW_GET_CAMPAIGN_DIRECTORIES_PHONE_TYPE_ERROR = 'SHOW_GET_CAMPAIGN_DIRECTORIES_PHONE_TYPE_ERROR'
export const SHOW_GET_CAMPAIGN_DIRECTORIES_CAMPAIGN_TYPE = 'SHOW_GET_CAMPAIGN_DIRECTORIES_CAMPAIGN_TYPE'
export const SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR = 'SHOW_GET_COMPANIES_DIRECTORIES_TRANKS_PHONE_ERROR'
export const GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY_ERROR = 'GET_COMPANIES_DIRECTORIES_CAMPAIGN_STRATEGY_ERROR'

export const GET_PAGINATION = 'GET_PAGINATION'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_CURRENT_SIZE = 'SET_CURRENT_SIZE'
export const GET_CAMPAIGN_CONTACTS = 'GET_CAMPAIGN_CONTACTS'
export const CLEAR_CAMPAIGN_CONTACTS = 'CLEAR_CAMPAIGN_CONTACTS'
export const SET_CAMPAIGN_CONTACTS_PAGE_SIZE = 'SET_CAMPAIGN_CONTACTS_PAGE_SIZE'
export const SET_CONTACTS_LOADER = 'SHOW_CONTACTS_LOADER'
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR'
export const SHOW_COMPANY_DELETE_MESSAGE = 'SHOW_COMPANY_DELETE_MESSAGE'
export const SET_USER_CONNECTED = 'SET_USER_CONNECTED'
export const SET_LOADING = 'SET_LOADING'
export const AUDIO_LOADING = 'AUDIO_LOADING'
export const UPLOAD_AUDIO_LOADING = 'UPLOAD_AUDIO_LOADING'
export const INITIAL_ACCOUNT_DATA = 'INITIAL_ACCOUNT_DATA'
export const SHOW_CAMPAIGN_DETAIL_LOADING = 'SHOW_CAMPAIGN_DETAIL_LOADING'
export const SET_ACTIVE_CLICK = 'SET_ACTIVE_CLICK'
export const INITIAL_ROLE_DATA = 'INITIAL_ROLE_DATA'
export const SET_ROLE_ERROR_FIELDS = 'SET_ROLE_ERROR_FIELDS'
export const GET_USERS_WEB_RTC = 'GET_USERS_WEB_RTC'
export const GET_ERROR_USERS_WEB_RTC = 'GET_ERROR_USERS_WEB_RTC'
export const GET_MUTE_ID = 'GET_MUTE_ID'
export const GET_MUTE_CONSULTATION_ID = 'GET_MUTE_CONSULTATION_ID'
export const SET_ACTIVE_SESSION = 'SET_ACTIVE_SESSION'
export const RECONNECT_SESSION = 'RECONNECT_SESSION'
export const CHECK_RECONNECT_SESSION = 'CHECK_RECONNECT_SESSION';
export const SUCCESS_SESSION_RECONNECTED = 'SUCCESS_SESSION_RECONNECTED'
export const SET_RECALL_NUMBER = 'SET_RECALL_NUMBER'
export const CLEAR_RECALL_SESSION = 'CLEAR_RECALL_SESSION'
export const CHANGE_SKILL_GROUP_LEVEL = 'CHANGE_SKILL_GROUP_LEVEL';
export const DELETE_SKILL_GROUP = 'DELETE_SKILL_GROUP';
export const ADD_SKILL_GROUP = 'ADD_SKILL_GROUP';
export const CHANGE_RECALL_NUMBER = 'CHANGE_RECALL_NUMBER';
export const GET_HASH_LOADING = 'GET_HASH_LOADING';
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_CURRENT_USER_PASSWORD_SUCCESS = 'CHANGE_CURRENT_USER_PASSWORD_SUCCESS';
export const SET_MONITORING_HUB_ERROR = 'SET_MONITORING_HUB_ERROR';
export const SET_STATUS_HUB_ERROR = 'SET_STATUS_HUB_ERROR';
export const SET_USER_DATA_HUB_ERROR = 'SET_USER_DATA_HUB_ERROR';
export const CAMPAIGN_ACD_FIELD = 'CAMPAIGN_ACD_FIELD';
