import {PROD} from "../redux/config";

export const statusesDict = [
    {name: 'UNSUCCESS', code: 'UNSUCCESS'},
    {name: 'SUCCESS', code: 'SUCCESS'},
    {name: 'ACTIVE', code: 'ACTIVE'},
    {name: 'ACTION', code: 'ACTION'},
    {name: 'PROCESSING', code: 'PROCESSING'}
];

export const activeDict = [
    {value: 'Активний', code: "true"},
    {value: 'Неактивний', code: "false"},
];

export const languages = [
    {name: 'en', id: 'en'},
    {name: 'uk', id: 'uk'},
];

export const voices = [
    {name: 'Жіночий', id: "female"},
    {name: 'Чоловічий', id: "male"},
];

export let isAudio = [
    {name: 'Аудіофайл', state: true},
    {name: 'Диктор', state: false},
];

if (PROD) {
    isAudio = [
        {name: 'Аудіофайл', state: true},
    ];
}
