import React, {useEffect, useRef, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import 'react-h5-audio-player/lib/styles.css';
import {Dialog} from "primereact/dialog";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {Paginator} from "primereact/paginator";
import {MultiSelect} from "primereact/multiselect";
import {getAllSimpleUsers, getUsers} from "../../../redux/api/api";
import {
    getStatisticsUserSortFields,
} from "../../../redux/api/apiStatisticsList";
import {
    setStatisticsCurrentPage,

} from "../../../redux/actions/actionsStatisticsList";
import CallsStatisticsDetails from "./CallsStatisticsDetails";
import CallsStatisticsListNav from "./CallsStatisticsListNav";
import {getCallStatisticsWithPaginationFilterAndSorting} from "../../../redux/api/apiCallsStatisticsList";
import {
    getUserCallsDetailsInfo,
    setCallsStatisticsCurrentPage, setCallsStatisticsCurrentPageSize,
    setCallStatisticsFilter
} from "../../../redux/actions/actionsCallsStatistics";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {GET_CALLS_STATISTICS} from "../../../redux/types/typesCallsStatisticsList";

const CallStatisticsList = (props: any) => {
    const dispatch = useAppDispatch();
    const statisticsCallsList = useAppSelector(state => state.StatisticsCallsListReducer?.statisticsCallsList);
    const statisticCallsData = useAppSelector(state => state.StatisticsCallsListReducer.statisticCallsData);
    const userStatisticSortFields: any = useAppSelector(state => state.RolesReducer.userStatisticSortFields);
    const campaignList = useAppSelector(state => state.MonitoringListReducer?.campaignListName);
    const paginationSize = useAppSelector(state => state.StatisticsCallsListReducer.paginationSize);
    const pagination = useAppSelector(state => state.StatisticsCallsListReducer.pagination);
    const loader = useAppSelector(state => state.StatisticsCallsListReducer.loading);
    const paginationPage = useAppSelector(state => state.StatisticsCallsListReducer.paginationPage);
    const clearFilters = useAppSelector(state => state.StatisticsCallsListReducer.clearFilters)
    const user = useAppSelector(state => state.UsersReducer.userData);
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);
    const [first, setFirst] = useState(0);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [selectedRowExpansion, setSelectedRowExpansion] = useState<any | null>(null);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [sortOrder, setSortOrder] = useState<number>(0);
    const [startTime, setStartTime] = useState<any>(new Date("2024-10-29 00:00:00"));
    const [endTime, setEndTime] = useState<any>(new Date());
    const [startFinishTime, setStartFinishTime] = useState<any>(null);
    const [endFinishTime, setEndFinishTime] = useState<any>(null);
    const [filterCampaignId, setFilterCampaignId] = useState<any>(null);
    const [filterNameTo, setFilterNameTo] = useState<any>(null);
    const [filterNameFrom, setFilterNameFrom] = useState<any>(null);
    const [filterAmdHangup, setFilterAmdHangup] = useState<any>(null);
    const [filterCallReset, setFilterCallReset] = useState<any>(null);
    const [filterStatus, setFilterStatus] = useState<any>(null);
    const [filterCampaignName, setFilterCampaignName] = useState<any>(null);
    const [filterId, setFilterId] = useState<any>(null);
    const [filterFullName, setFilterFullName] = useState<any>(null);
    const [filterTypeChannel, setFilterTypeChannel] = useState<any>(null);
    const [filterDirection, setFilterDirection] = useState<any>(null);
    const [filterDateTime, setFilterDateTime] = useState<any>(new Date());
    const [filterEndDateTime, setFilterEndDateTime] = useState<any>(new Date());
    const [filterFinishDateTime, setFilterFinishDateTime] = useState<any>(false);
    const [filterFinishEndDateTime, setFilterFinishEndDateTime] = useState<any>(false);
    const [fullCallDurationStartTime, setfullCallDurationStartTime] = useState<any>(false);
    const [fullCallDurationEndTime, setfullCallDurationEndTime] = useState<any>(false);
    const [durationStartTime, setDurationStartTime] = useState<any>(false);
    const [durationEndTime, setDurationEndTime] = useState<any>(false);
    const [reactionClientStartTime, setReactionClientStartTime] = useState<any>(false);
    const [reactionClientEndTime, setReactionClientEndTime] = useState<any>(false);
    const [reactionOperatorStartTime, setReactionOperatorStartTime] = useState<any>(false);
    const [reactionOperatorEndTime, setReactionOperatorEndTime] = useState<any>(false);
    const [visible, setVisible] = useState(false);
    const [filterCallOwner, setFilterCallOwner] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<string>('table1');
    const dataTableRef = useRef<DataTable | any>(null);
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const usersData = useAppSelector(state => state.CompaniesListReducer.allSimpleUsers);
    const [selectedColumns, setSelectedColumns] = useState<any>(userStatisticSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    const pageChanged = useAppSelector(state => state.StatisticsCallsListReducer.pageChanged);
    const [fullCallDuration, setFullCallDuration] = useState<boolean>(false);
    const [duration, setDuration] = useState<boolean>(false);
    const [reactionClient, setReactionClient] = useState<boolean>(false);
    const [reactionOperator, setReactionOperator] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(paginationSize);
    const [hasStartFilter, setHasStartFilter] = useState<boolean>(false);
    const [allTimeChecked, setAllTimeChecked] = useState<boolean | null>(false);

    const formatDate = (dateString: Date) => {
        const dateObj = new Date(dateString);

        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObj.getDate().toString().padStart(2, "0");
        const year = dateObj.getFullYear();

        return `${month}/${day}/${year}`;
    };

    useEffect(() => {
        dispatch(getAllSimpleUsers(user));
    }, []);

    useEffect(() => {
        setStartTime(new Date("2024-10-29 00:00:00"));
        setEndTime(new Date());
        setFilterDateTime(new Date());
        setFilterEndDateTime(new Date());
    }, []);

    const formatDateTime = (dateString: any) => {
        const date = new Date(dateString);
        return `${date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'})} ${date.toLocaleDateString('en-GB')}`;
    };

    useEffect(() => {
        if (allTimeChecked !== null && allTimeChecked) {
            setStartTime(new Date("2000-01-01 00:00:00"));
            setEndTime(() => {
                const date = new Date();
                date.setSeconds(0, 0);
                return date;
            });
            setFilterDateTime(new Date("2000-01-01 00:00:00"));
            setFilterEndDateTime(new Date());
        } else {
            setStartTime(new Date("2000-01-01 00:00:00"));
            setEndTime(() => {
                const date = new Date();
                date.setSeconds(0, 0);
                return date;
            });
            setFilterDateTime(new Date());
            setFilterEndDateTime(new Date());
        }
    }, [allTimeChecked]);

    const getTime = (dateString: Date | any) => {
        const dateObj = new Date(dateString);
        const hours = dateObj.getHours().toString().padStart(2, "0");
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");

        return `${hours}:${minutes}:00`;
    };

    let startDate = formatDate(filterDateTime);
    let endDate = formatDate(filterEndDateTime);
    let filterStartTime = getTime(startTime);
    let filterEndTime = getTime(endTime);

    const [sortData, setSortData] = useState<any>({
        pageNumber: 1,
        pageSize: 10,
        sort: {
            orderBy: orderBy ? orderBy : "Id",
            isDesc: isDescFilter
        },
        filter: [
            {
                "key": "Start",
                "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
            }
        ],
        username: user.userName
    });

    if (!userStatisticSortFields?.userId) {
        dispatch(getStatisticsUserSortFields(jwtToken?.jwtToken));
    }

    useEffect(() => {
        if (pagination.hasNext && pagination.pageNumber) {
            setTotalItems((pagination.pageNumber * paginationSize) + paginationSize)
        } else if (!pagination.hasNext) {
            setTotalItems(pagination.pageNumber * paginationSize);
        }
    }, [pagination]);

    useEffect(() => {
        setTotalItems((pagination.pageNumber * paginationSize) + paginationSize);
    }, [])


    useEffect(() => {
        setSelectedColumns(userStatisticSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    }, []);

    useEffect(() => {
        setFirst(0);
    }, [pageChanged])

    useEffect(() => {
        setSelectedColumns(userStatisticSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    }, [userStatisticSortFields]);

    const createUuidv4 = () => {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            // @ts-ignore
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    const formatItem = (item: { start: any; }) => {
        if (item && item.start) {
            return {
                ...item,
                start: formatDateTime(item.start),
                dateTime: createUuidv4()
            };
        }
        return item;
    };

    const formatDataRecursively = (obj: any) => {
        const formattedObj = {
            ...obj,
            item: formatItem(obj.item),
        };

        if (formattedObj.children && formattedObj.children.length > 0) {
            formattedObj.children = formattedObj.children.map((child: any) => formatDataRecursively(child));
        }

        return formattedObj;
    };

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setStartTime(new Date("2024-10-29 00:00:00"));
        setEndTime(new Date());
        setFilterEndDateTime(null);
        setStartFinishTime(null);
        setEndFinishTime(null);
        setFilterFinishDateTime(null);
        setFilterFinishEndDateTime(null);
        setSortOrder(0);
        setFirst(0);
        dispatch(setCallsStatisticsCurrentPage(1));
        dispatch(setCallsStatisticsCurrentPageSize(10));
        setFilterId(null);
        setReactionOperator(false);
        setFullCallDuration(false);
        setDuration(false);
        setReactionOperatorStartTime(null);
        setReactionOperatorEndTime(null);
        setReactionOperator(false);
        setDurationStartTime(null);
        setDurationEndTime(null);
        setfullCallDurationStartTime(null);
        setfullCallDurationEndTime(null);
        setReactionClientStartTime(null);
        setReactionClientEndTime(null);
        setReactionClient(false);
        setHasStartFilter(!hasStartFilter);
        setAllTimeChecked(false);
        setFilterDateTime(new Date());
        setFilterEndDateTime(new Date());
        setSortData({
            pageNumber: 1,
            pageSize: 10,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            },
            username: user.userName
        })
    };


    useEffect(() => {
        dispatch(setCallsStatisticsCurrentPage(1));
    }, []);

    useEffect(() => {
        dispatch(setCallsStatisticsCurrentPageSize(10));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onPageChange = (event: { first: React.SetStateAction<number>; page: number; rows: any; }) => {
        setFirst(event.first);
        dispatch(setCallsStatisticsCurrentPage(event.page + 1));
        dispatch(setCallsStatisticsCurrentPageSize(event.rows));
    };

    const resetTableHorizontalScroll = () => {
        const scrollableBody = document.querySelector('.p-datatable-wrapper');
        if (scrollableBody) {
            scrollableBody.scrollTop = 0; // Сброс прокрутки
            scrollableBody.scrollLeft = 0;
        }
    };

    useEffect(() => {
        setSelectedRowData(null);
        let filter: {
            key: string;
            value: string
        }[] = [];

        const dataSort: any = {
            pageNumber: paginationPage,
            pageSize: paginationSize,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            },
            username: user.userName
        }

        if (filterCampaignId?.value) {
            filter.push(filterCampaignId);
        }
        if (filterNameTo?.value) {
            filter.push(filterNameTo);
        }
        if (filterNameFrom?.value) {
            filter.push(filterNameFrom);
        }
        if (filterTypeChannel) {
            filterTypeChannel?.map((item: any) => {
                if (item.value === 'internalSkipped') {
                    filter.push({key: 'IsDeclined', value: '1'});
                } else {
                    filter.push(item);
                }
            })
        }

        if (filterCampaignName) {
            filterCampaignName?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterCallOwner) {
            filterCallOwner?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterId) {
            filter.push(filterId);
        }

        if (filterFullName) {
            filterFullName?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterStatus) {
            filterStatus.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterDirection) {
            filterDirection.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterAmdHangup) {
            filterAmdHangup.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterCallReset) {
            filterCallReset.map((item: any) => {
                filter.push(item);
            })
        }


        let startAdded = false;
        let endAdded = false;

        if (filterDateTime && filterEndDateTime) {
            let startDate = formatDate(filterDateTime);
            let endDate = formatDate(filterEndDateTime);

            if (startTime && endTime) {
                let filterStartTime = getTime(startTime);
                let filterEndTime = getTime(endTime);
                filter.push({
                    "key": "Start",
                    "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
                });
                startAdded = true;
            }
        }

        if (filterFinishDateTime && filterFinishEndDateTime) {
            let startDate = formatDate(filterFinishDateTime);
            let endDate = formatDate(filterFinishEndDateTime);

            if (startFinishTime && endFinishTime) {
                let filterStartTime = getTime(startFinishTime);
                let filterEndTime = getTime(endFinishTime);
                filter.push({
                    "key": "End",
                    "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
                });
                endAdded = true;
            }
        }

        if (!startAdded && !endAdded) {
            let currentDateTime = new Date();
            let currentDate = formatDate(currentDateTime);
            let currentTime = getTime(currentDateTime);
            filter.push({
                "key": "Start",
                "value": `${currentDate} 00:00:00,${currentDate} ${currentTime}`
            });
        }

        const addFilterSecond = (condition: boolean, key: string, startTime: number, endTime: number) => {
            if (
                condition &&
                startTime !== undefined && startTime !== null &&
                endTime !== undefined && endTime !== null
            ) {
                filter.push({
                    "key": key,
                    "value": `${startTime}, ${endTime}`
                });
            }
        };

        const getTotalSeconds = (date: any) => {
            if (!date) return null;
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            return hours * 3600 + minutes * 60 + seconds;
        };


        if (reactionClientStartTime && reactionClientEndTime) {
            addFilterSecond(reactionClient, "ReactionClient", getTotalSeconds(reactionClientStartTime), getTotalSeconds(reactionClientEndTime));
        }
        if (reactionOperatorStartTime && reactionOperatorEndTime) {
            addFilterSecond(reactionOperator, "ReactionOperator", getTotalSeconds(reactionOperatorStartTime), getTotalSeconds(reactionOperatorEndTime));
        }
        if (fullCallDurationStartTime && fullCallDurationEndTime) {
            addFilterSecond(fullCallDuration, "FullCallDuration", getTotalSeconds(fullCallDurationStartTime), getTotalSeconds(fullCallDurationEndTime));
        }
        if (durationStartTime && durationEndTime) {
            addFilterSecond(duration, "Duration", getTotalSeconds(durationStartTime), getTotalSeconds(durationEndTime));
        }

        if (filter) {
            dataSort['filter'] = filter;
        }

        const startEntry = dataSort?.filter?.length
            ? dataSort.filter.find((item: { key: string; }) => item.key === "Start")
            : undefined;

        if (allTimeChecked !== null && startEntry) {
            collapseAll();
            dispatch(getCallStatisticsWithPaginationFilterAndSorting(dataSort, jwtToken?.jwtToken));
            dispatch(setCallStatisticsFilter(dataSort));
            setSortData(dataSort);
            resetTableHorizontalScroll();
        } else {
            dispatch({type: GET_CALLS_STATISTICS, payload: null})
        }

    }, [paginationPage, paginationSize, orderBy, isDescFilter, sortOrder, filterCampaignId, filterNameTo, filterTypeChannel, filterStatus, reactionClient, reactionOperator, duration, fullCallDuration, hasStartFilter]);

    useEffect(() => {
        if (activeClick?.item === "/calls" && activeClick?.from === '#/calls') {
            dispatch(getCallStatisticsWithPaginationFilterAndSorting(sortData, jwtToken?.jwtToken));
            resetTableHorizontalScroll();
        }
    }, [activeClick]);

    const monitoringSort = (event: { sortField: any; }) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;
            switch (sortField) {
                case 'ID':
                    newOrderBy = 'Id';
                    break;
                case 'FullName':
                    newOrderBy = 'FullName';
                    break;
                case 'AllCountUserCalls':
                    newOrderBy = 'AllCountUserCalls';
                    break;
                case 'AllUserFullduration':
                    newOrderBy = 'AllUserFullduration';
                    break;
                case 'AllUserAVGduration':
                    newOrderBy = 'AllUserAVGduration';
                    break;
                case 'InternalSuccessCount':
                    newOrderBy = 'InternalSuccessCount';
                    break;
                case 'InternalSuccessFullduration':
                    newOrderBy = 'InternalSuccessFullduration';
                    break;
                case 'InternalSuccessAVGduration':
                    newOrderBy = 'InternalSuccessAVGduration';
                    break;
                case 'InternalUnsuccessCount':
                    newOrderBy = 'InternalUnsuccessCount';
                    break;
                case 'InternalUnsuccessFullduration':
                    newOrderBy = 'InternalUnsuccessFullduration';
                    break;
                case 'InternalUnsuccessAVGduration':
                    newOrderBy = 'InternalUnsuccessAVGduration';
                    break;
                case 'ExternalSuccessCount':
                    newOrderBy = 'ExternalSuccessCount';
                    break;
                case 'ExternalSuccessFullduration':
                    newOrderBy = 'ExternalSuccessFullduration';
                    break;
                case 'ExternalSuccessAVGduration':
                    newOrderBy = 'ExternalSuccessAVGduration';
                    break;
                case 'ExternalUnsuccessCount':
                    newOrderBy = 'ExternalUnsuccessCount';
                    break;
                case 'ExternalUnsuccessFullduration':
                    newOrderBy = 'ExternalUnsuccessFullduration';
                    break;
                case 'ExternalUnsuccessAVGduration':
                    newOrderBy = 'ExternalUnsuccessAVGduration';
                    break;
                case 'CampaignSuccessCount':
                    newOrderBy = 'CampaignSuccessCount';
                    break;
                case 'CampaignSuccessFullduration':
                    newOrderBy = 'CampaignSuccessFullduration';
                    break;
                case 'CampaignSuccessAVGduration':
                    newOrderBy = 'CampaignSuccessAVGduration';
                    break;
                case 'CampaignUnsuccessCount':
                    newOrderBy = 'CampaignUnsuccessCount';
                    break;
                case 'CampaignUnsuccessFullduration':
                    newOrderBy = 'CampaignUnsuccessFullduration';
                    break;
                case 'CampaignUnsuccessAVGduration':
                    newOrderBy = 'CampaignUnsuccessAVGduration';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
            dispatch(setStatisticsCurrentPage(1));
            setFirst(0);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };

    const filterClearTemplate = (options: { filterClearCallback: React.MouseEventHandler<HTMLButtonElement> | undefined; field: string }) => {
        return <Button type="button" className="p-button-secondary" icon="pi pi-times" onClick={(e) => {
            if (options.filterClearCallback) {
                options.filterClearCallback(e);
            }
            if (options.field === 'start') {
                setStartTime(null);
                setEndTime(null);
                setFilterDateTime(null);
                setFilterEndDateTime(null);
            }
            if (options.field === 'end') {
                setStartFinishTime(null);
                setEndFinishTime(null);
                setFilterFinishDateTime(null);
                setFilterFinishEndDateTime(null);
            }
            if (options.field === 'reactionClient') {
                setReactionClientStartTime(null);
                setReactionClientEndTime(null);
                setReactionClient(false);
            }
            if (options.field === 'reactionOperator') {
                setReactionOperatorStartTime(null);
                setReactionOperatorEndTime(null);
                setReactionOperator(false);
            }
            if (options.field === 'duration') {
                setDuration(false);
                setDurationStartTime(null);
                setDurationEndTime(null);
            }
            if (options.field === 'fullCallDuration') {
                setfullCallDurationStartTime(null);
                setfullCallDurationEndTime(null);
                setFullCallDuration(false);
            }
        }}></Button>;
    }

    const filterApplyTemplate = (options: { filterApplyCallback: React.MouseEventHandler<HTMLButtonElement> | undefined; field: string; }) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className=""
                       disabled={
                           (options.field === 'duration' && (!durationStartTime || !durationEndTime)) ||
                           (options.field === 'fullCallDuration' && (!fullCallDurationStartTime || !fullCallDurationEndTime)) ||
                           (options.field === 'reactionOperator' && (!reactionOperatorStartTime || !reactionOperatorEndTime)) ||
                           (options.field === 'reactionClient' && (!reactionClientStartTime || !reactionClientEndTime)) ||
                           (options.field === 'start' && (!startTime || !endTime || !filterDateTime || !filterEndDateTime)) ||
                           (options.field === 'end' && (!startFinishTime || !endFinishTime || !filterFinishDateTime || !filterFinishEndDateTime))}></Button>
    }

    useEffect(() => {
        dispatch(getUsers(user));
    }, []);

    const customFilter = (event?: any) => {
        if (event) {
            const filters = event.filters;
            const {end} = event.filters;
            const {fullCallDuration} = event.filters;
            const {duration} = event.filters;
            const {reactionClient} = event.filters;
            const {reactionOperator} = event.filters;

            setFullCallDuration(!!fullCallDuration.value);
            setDuration(!!duration.value);
            setReactionClient(!!reactionClient.value);
            setReactionOperator(!!reactionOperator.value);
            setFilterFinishDateTime(end.value || null);
            const setFilter = (filterName: string, key: string, defaultValue?: any | null) => {
                if (!filters[filterName]) {
                    return defaultValue;
                }

                const value = filters[filterName].value || filters[filterName].constraints?.[0]?.value;
                if (!value) {
                    return defaultValue;
                }

                if (Array?.isArray(value)) {
                    return value.map((item) => ({key, value: String(item)}));
                } else {
                    return {key, value: String(value)};
                }
            };

            setFilterCampaignId(setFilter('campaignId', 'CampaignId'));
            setFilterNameTo(setFilter('nameTo', 'NameTo'));
            setFilterNameFrom(setFilter('nameFrom', 'NameFrom'));
            setFilterTypeChannel(setFilter('typeChannel', 'TypeChannel', []));
            setFilterFullName(setFilter('fullName', 'UserName', []));
            setFilterCampaignName(setFilter('campaignFullName', 'CampaignId', []));
            setFilterCallOwner(setFilter('ownerCall', 'OwnerCall', []));
            setFilterId(setFilter('id', 'Id', null));
            setFilterDirection(setFilter('direction', 'Direction', []));
            setFilterStatus(setFilter('status', 'Status', []));
            setFilterAmdHangup(setFilter('amdHangup', 'ForcedClosing', []));
            setFilterCallReset(setFilter('callResetName', 'CallReset', []));
            dispatch(setCallsStatisticsCurrentPage(1));
            setFirst(0);
        } else {
            setFilterCampaignId(null);
            setFilterFullName(null);
            setFilterNameTo(null);
            setFilterNameFrom(null);
            setFilterStatus(null);
            setFilterCampaignName(null);
            setFilterTypeChannel(null);
            setFilterAmdHangup(null);
            setFilterCallReset(null);
            setOrderBy(null);
            setIsDescFilter(true);
            setFilterDirection(null);
            setFilterId(null);
            setFilterCallOwner(null);
        }
    };

    const operatorItemTemplate = (item: any) => {
        return <div className="flex justify-content-between"><div>{item.fullName}</div> <div className="ml-3 text-sm">{!item?.isActive && 'Архівний'}</div></div>;
    }

    const operatorFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    optionValue="userName"
                    selectedItemsLabel={options.value ? `Обрано: ${options.value.length}` : ""}
                    filter
                    options={usersDataWithFullName}
                    maxSelectedLabels={0}
                    optionLabel="fullName"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                    itemTemplate={operatorItemTemplate}
                    itemClassName="w-full operatorItem"
                />
            </>
        );
    };

    const renderHeader = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center cursor-pointer"
                 onClick={() => monitoringSort({'sortField': sortField})}>
                {field} <i className={sortIcon + ' pl-2'}></i>
            </div>
        );
    };

    const toggleTab = (tableName: string) => {
        setSelectedRowData(null);
        setActiveTab(tableName);
    };

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const usersDataWithFullName = usersData?.map((userData: any) => {
        return {
            ...userData,
            fullName: `${userData?.lastName} ${userData?.firstName} ${userData?.middleName ? `${userData.middleName}` : ''}`
        }
    });

    const campaignListWithId = campaignList?.campaigns?.map((campaignData: any) => {
        return {
            ...campaignData,
            campaignWithId: `${campaignData.id} ${campaignData.name}`
        }
    });

    const collapseAll = () => {
        setExpandedRows(null);
    };

    const dynamicRowClass = (data: any) => {
        if (data?.id === selectedRowData?.id) {
            return 'mainRowMonitoringTable p-highlight';
        }
        return 'mainRowMonitoringTable';
    };

    const convertMillisecondsToTime = (totalMilliseconds: number): string => {
        const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

        const remainingMillisecondsForSeconds = totalMilliseconds % (1000 * 60);
        const seconds = remainingMillisecondsForSeconds >= 500
            ? Math.ceil(remainingMillisecondsForSeconds / 1000)
            : Math.floor(remainingMillisecondsForSeconds / 1000);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const headerIdBlock = () => {
        return <div className="flex">
            <div style={{width: '70px', minWidth: '70px', maxWidth: '70px'}}>{renderHeader('ID', 'ID')}</div>
            <div style={{width: '170px', minWidth: '170px', maxWidth: '170px'}}>{renderHeader('ПІБ', 'FullName')}</div>
        </div>
    }

    const bodyIdTemplate = (data: any) => {
        return <div className="flex align-items-center">
            <div style={{width: '70px', minWidth: '70px', maxWidth: '70px'}}>{data?.id}</div>
            <div style={{width: '155px', minWidth: '155px', maxWidth: '155px'}}>{data?.fullName}</div>
        </div>
    }

    const headerTitleAllCountUserCalls = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Кількість', 'AllCountUserCalls')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Тривалість', 'AllUserFullduration')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Середня', 'AllUserAVGduration')}</div>
        </div>
    }

    const headerTitleInternalUserCalls = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Кількість', 'InternalSuccessCount')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Тривалість', 'InternalSuccessFullduration')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Середня', 'InternalSuccessAVGduration')}</div>
        </div>
    }

    const headerTitleInternalUnsuccessUserCalls = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Кількість', 'InternalUnsuccessCount')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Тривалість', 'InternalUnsuccessFullduration')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Середня', 'InternalUnsuccessAVGduration')}</div>
        </div>
    }

    const headerTitleExternalSuccessUserCalls = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Кількість', 'ExternalSuccessCount')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Тривалість', 'ExternalSuccessFullduration')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Середня', 'ExternalSuccessAVGduration')}</div>
        </div>
    }

    const headerTitleExternalUnuccessUserCalls = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Кількість', 'ExternalUnsuccessCount')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Тривалість', 'ExternalUnsuccessFullduration')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Середня', 'ExternalUnsuccessAVGduration')}</div>
        </div>
    }

    const headerTitleCampaignSuccessCountUserCalls = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Кількість', 'CampaignSuccessCount')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Тривалість', 'CampaignSuccessFullduration')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Середня', 'CampaignSuccessAVGduration')}</div>
        </div>
    }

    const headerTitleCampaignUnsuccessCountUserCalls = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Кількість', 'CampaignUnsuccessCount')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Тривалість', 'CampaignUnsuccessFullduration')}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{renderHeader('Середня', 'CampaignUnsuccessAVGduration')}</div>
        </div>
    }

    const headerTitleAllUser = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.allCountUserCalls || '- - -'} </div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.allUserFullduration ? convertMillisecondsToTime(statisticsCallsList?.all?.allUserFullduration) : '- - -'}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.allUserAVGduration ? convertMillisecondsToTime(statisticsCallsList?.all?.allUserAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleAllInternal = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.internalSuccessCount} </div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.internalSuccessFullduration ? convertMillisecondsToTime(statisticsCallsList?.all?.internalSuccessFullduration) : '- - -'}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.internalSuccessAVGduration ? convertMillisecondsToTime(statisticsCallsList?.all?.internalSuccessAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleAllInternalUnsuccess = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.internalUnsuccessCount} </div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.internalUnsuccessFullduration ? convertMillisecondsToTime(statisticsCallsList?.all?.internalUnsuccessFullduration) : '- - -'}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.internalUnsuccessAVGduration ? convertMillisecondsToTime(statisticsCallsList?.all?.internalUnsuccessAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleExternalSuccess = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.externalSuccessCount} </div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.externalSuccessFullduration ? convertMillisecondsToTime(statisticsCallsList?.all?.externalSuccessFullduration) : '- - -'}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.externalSuccessAVGduration ? convertMillisecondsToTime(statisticsCallsList?.all?.externalSuccessAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleExternalUnsuccess = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.externalUnsuccessCount} </div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.externalUnsuccessFullduration ? convertMillisecondsToTime(statisticsCallsList?.all?.externalUnsuccessFullduration) : '- - -'}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.externalUnsuccessAVGduration ? convertMillisecondsToTime(statisticsCallsList?.all?.externalUnsuccessAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleCampaignSuccess = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.campaignSuccessCount} </div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.campaignSuccessFullduration ? convertMillisecondsToTime(statisticsCallsList?.all?.campaignSuccessFullduration) : '- - -'}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.campaignSuccessAVGduration ? convertMillisecondsToTime(statisticsCallsList?.all?.campaignSuccessAVGduration) : '- - -'}</div>
        </div>
    }

    const headerTitleCampaignUnsuccess = () => {
        return <div className="flex">
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.campaignUnsuccessCount} </div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.campaignUnsuccessFullduration ? convertMillisecondsToTime(statisticsCallsList?.all?.campaignUnsuccessFullduration) : '- - -'}</div>
            <div
                style={{width: '120px', minWidth: '120px', maxWidth: '120px'}}>{statisticsCallsList?.all?.campaignUnsuccessAVGduration ? convertMillisecondsToTime(statisticsCallsList?.all?.campaignUnsuccessAVGduration) : '- - -'}</div>
        </div>
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Оператор" headerStyle={{width: '240px', minWidth: '240px', maxWidth: '240px'}}/>
                <Column header="Всього дзвінків"
                        headerStyle={{width: '360px', minWidth: '360px', maxWidth: '360px', borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header="Вхідні успішні"
                        headerStyle={{width: '360px', minWidth: '360px', maxWidth: '360px', borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header="Вхідні неуспішні"
                        headerStyle={{width: '360px', minWidth: '360px', maxWidth: '360px', borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header="Вихідні успішні"
                        headerStyle={{width: '360px', minWidth: '360px', maxWidth: '360px', borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header="Вихідні неуспішні"
                        headerStyle={{width: '360px', minWidth: '360px', maxWidth: '360px', borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header="Кампанії успішні"
                        headerStyle={{width: '360px', minWidth: '360px', maxWidth: '360px', borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header="Кампанії неуспішні"
                        headerStyle={{width: '360px', minWidth: '360px', maxWidth: '360px', borderLeft: '1px solid grey'}}/>
            </Row>
            <Row>
                <Column className={'Id-FullName-Header'} header={headerIdBlock} filter filterField={'fullName'}
                        showFilterMatchModes={false} filterElement={operatorFilterTemplate}
                        filterApply={filterApplyTemplate} filterClear={filterClearTemplate}
                        showFilterMenuOptions={false}/>
                <Column header={headerTitleAllCountUserCalls}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleInternalUserCalls}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleInternalUnsuccessUserCalls}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleExternalSuccessUserCalls}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleExternalUnuccessUserCalls}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleCampaignSuccessCountUserCalls}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleCampaignUnsuccessCountUserCalls} style={{borderLeft: '1px solid grey'}}/>
            </Row>
            <Row>
                <Column header="Усього по операторам" style={{borderRight: '1px solid grey'}}/>
                <Column header={headerTitleAllUser}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleAllInternal}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleAllInternalUnsuccess}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleExternalSuccess}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleExternalUnsuccess}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleCampaignSuccess}
                        style={{borderRight: '1px solid grey', borderLeft: '1px solid grey'}}/>
                <Column header={headerTitleCampaignUnsuccess} style={{borderLeft: '1px solid grey'}}/>
            </Row>
        </ColumnGroup>
    );
    const renderTable = () => {
        return (
            <div className="layout-dashboard">
                <div className="flex flex-wrap col-12 md:col-12">
                    <div
                        className={classNames('card widget-table mr-4 col-12 transition-duration-300 monitoring-table align-self-start monitoringLoader', {'': (selectedRowData || selectedRowExpansion) && !isMobile(), 'md:col-12': !(selectedRowData || selectedRowExpansion)})}>
                        <CallsStatisticsListNav
                            setAllTimeChecked={setAllTimeChecked}
                            profileMenuPin={!!props.profileMenuPin}
                            allTimeChecked={allTimeChecked}
                            hasStartFilter={hasStartFilter} setHasStartFilter={setHasStartFilter}
                            setEndTime={setEndTime} endTime={endTime} startTime={startTime}
                            setStartTime={setStartTime} setFilterEndDateTime={setFilterEndDateTime}
                            filterEndDateTime={filterEndDateTime}
                            setFilterDateTime={setFilterDateTime}
                            filterDateTime={filterDateTime}
                            userSortFields={userStatisticSortFields?.fields} sortData={sortData}
                            resetSorting={handleReset} toggleTab={toggleTab}
                            activeTab={activeTab}/>
                        <DataTable
                            headerColumnGroup={headerGroup}
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            scrollable
                            scrollHeight={(selectedRowData || selectedRowExpansion) && !isMobile() ? '40vh' : '70vh'}
                            ref={dataTableRef}
                            rows={10}
                            onSort={monitoringSort}
                            filters={clearFilters}
                            loading={loader}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            value={statisticsCallsList?.users}
                            className="p-datatable-customers monitoring-table monitoring-table-rowGroup"
                            rowClassName={dynamicRowClass}
                            selectionMode="single"
                            emptyMessage="Нічого не знайдено"
                            rowHover={false}
                            onFilter={customFilter}
                            onRowClick={(e) => {
                                setSelectedRowExpansion(null);
                                dispatch(getUserCallsDetailsInfo(e.data));
                                if (!isMobile) {
                                    if (props.profileMenuPin === true) {
                                        setSelectedRowData(e.data);
                                        setVisible(true);
                                    } else {
                                        setSelectedRowData(e.data);
                                    }
                                } else {
                                    setSelectedRowData(e.data);
                                    setVisible(true);
                                }
                            }} dataKey="item.id"
                        >
                            <Column body={bodyIdTemplate}/>
                            <Column
                                body={(data) => data?.allCountUserCalls}
                                style={{minWidth: '120px'}}
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.allUserFullduration)}
                                style={{minWidth: '120px'}}
                                className=""
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.allUserAVGduration)}
                                style={{minWidth: '120px'}}
                            />

                            <Column
                                body={(data) => data?.internalSuccessCount}
                                style={{minWidth: '120px'}}
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.internalSuccessFullduration)}
                                style={{minWidth: '120px'}}
                                className=""
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.internalSuccessAVGduration)}
                                style={{minWidth: '120px'}}
                            />

                            <Column
                                body={(data) => data?.internalUnsuccessCount}
                                style={{minWidth: '120px'}}
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.internalUnsuccessFullduration)}
                                style={{minWidth: '120px'}}
                                className=""
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.internalUnsuccessAVGduration)}
                                style={{minWidth: '120px'}}
                            />


                            <Column
                                body={(data) => data?.externalSuccessCount}
                                style={{minWidth: '120px'}}
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.externalSuccessFullduration)}
                                style={{minWidth: '120px'}}
                                className=""
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.externalSuccessAVGduration)}
                                style={{minWidth: '120px'}}
                            />

                            <Column
                                body={(data) => data?.externalUnsuccessCount}
                                style={{minWidth: '120px'}}
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.externalUnsuccessFullduration)}
                                style={{minWidth: '120px'}}
                                className=""
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.externalUnsuccessAVGduration)}
                                style={{minWidth: '120px'}}
                            />


                            <Column
                                body={(data) => data?.campaignSuccessCount}
                                style={{minWidth: '120px'}}
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.campaignSuccessFullduration)}
                                style={{minWidth: '120px'}}
                                className=""
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.campaignSuccessAVGduration)}
                                style={{minWidth: '120px'}}
                            />

                            <Column
                                body={(data) => data?.campaignUnsuccessCount}
                                style={{minWidth: '120px'}}
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.campaignUnsuccessFullduration)}
                                style={{minWidth: '120px'}}
                                className=""
                            />
                            <Column
                                body={(data) => convertMillisecondsToTime(data?.campaignUnsuccessAVGduration)}
                                style={{minWidth: '120px'}}
                            />

                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                            template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                        />
                    </div>
                    {(selectedRowData || selectedRowExpansion) && statisticCallsData && (
                        <>
                            {windowWidth > 1248 && <div
                                className={classNames('card widget-table col-12 md:col-12 align-self-start sticky top-0')}>
                                <CallsStatisticsDetails setSelectedRowData={setSelectedRowData}
                                                        setSelectedRowExpansion={setSelectedRowExpansion}
                                />
                            </div>}

                            {windowWidth <= 1248 && <div>
                                <Dialog visible={visible} className="monitoring-info"
                                        header={statisticCallsData?.fullName} headerClassName={"pl-5  text-xs"}
                                        onHide={() => setVisible(false)}>
                                    <CallsStatisticsDetails setSelectedRowData={setSelectedRowData}
                                                            setSelectedRowExpansion={setSelectedRowExpansion}
                                    />
                                </Dialog>
                            </div>}
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderTable()}
        </div>
    );
};

export default CallStatisticsList;
